<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-card-text>
            <h3 class="primary--text">
              <v-icon
                left
                color="primary"
              >
                {{ icons.mdiCog }}
              </v-icon>{{ serverConfig.name }} Admin Panel
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs
      v-model="currentTab"
      icons-and-text
      grow
    >
      <v-tab>
        Users
        <v-icon>{{ icons.mdiAccountMultipleOutline }}</v-icon>
      </v-tab>

      <v-tab>
        Roles
        <v-icon>{{ icons.mdiShieldAccount }}</v-icon>
      </v-tab>

      <v-tab>
        Run Custom Code
        <v-icon>{{ icons.mdiCog }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="i in 3"
        :key="i"
      >
        <v-card flat>
          <user-list v-if="currentTab === 0"></user-list>
          <roles v-if="currentTab === 1"></roles>
          <v-btn
            v-if="currentTab === 2"
            color="primary"
            class="ma-5"
            @click="runCustomCode"
          >
            Test Code
          </v-btn>
          <v-btn @click="queryHistoryLoad()">
            Query History Load
          </v-btn>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<!--
<template>
  <div class="auth-wrapper auth-v2">
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-card-text>
            <h3 class="primary--text">
              <v-icon
                left
                color="primary"
              >
                {{ icons.mdiCog }}
              </v-icon>{{ serverConfig.name }} Admin Panel
            </h3>
          </v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiAccountMultipleOutline }}</v-icon><strong>{{ serverConfig.name }} Users</strong></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <user-list></user-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiCog }}</v-icon>Test Code</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-btn
                  color="primary"
                  @click="testCode"
                >
                  Test Code
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
-->

<script>
// eslint-disable-next-line object-curly-newline
import customCode from '@/customCode'
import { executeQueryHistoryBackfill } from '@/snowflake/snowflakeAdministration'
import { mdiAccountMultipleOutline, mdiCog, mdiShieldAccount } from '@mdi/js'
import serverConfig from '@serverConfig'
import { ref } from '@vue/composition-api'
import Roles from './Roles.vue'
import UserList from './UserList.vue'

export default {
  components: {
    Roles,
    UserList,
  },
  setup() {
    const currentTab = ref(0)
    const runCustomCode = async () => {
      console.log('Custom Code Beginning...')
      try {
        await customCode()
        console.log('Custom Code Finished Successfully...')
      } catch (err) {
        console.log(`Custom Code Failed: ${err}`)
      }
    }

    const queryHistoryLoad = async () => {
      await executeQueryHistoryBackfill()
    }

    return {
      serverConfig,
      queryHistoryLoad,
      runCustomCode,
      currentTab,
      icons: {
        mdiCog,
        mdiAccountMultipleOutline,
        mdiShieldAccount,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>

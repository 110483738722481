<template>
  <div>
    <add-new-user
      v-if="addNewUserSidebar"
      :add-new-user-sidebar="addNewUserSidebar"
      @close-sidebar="addNewUserSidebar = false"
    >
    </add-new-user>
    <v-row>
      <v-col sm="12">
        <v-card flat>
          <v-card-text>
            <v-row class="mt-1">
              <h3 class="primary--text">
                <v-icon
                  left
                  color="primary"
                >
                  {{ icons.mdiAccountMultipleOutline }}
                </v-icon>{{ serverConfig.name }} Users
              </h3>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="addNewUserSidebar = true"
              >
                <v-icon left>
                  {{ icons.mdiAccountPlus }}
                </v-icon>
                New User
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- table -->
    <v-data-table
      :headers="userListHeaders"
      :items="appUsers"
      :items-per-page="25"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            :color="item.avatar ? '' : 'primary'"
            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img
              v-if="item.avatar"
              :src="require(`@/assets/images/avatars/${item.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-medium"
            >{{ avatarText(item.name) }}</span>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            <!-- <router-link
              :to="{ name : 'apps-user-view', params : { id : item.name } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.name }}
            </router-link> -->
            <small>{{ item.email }}</small>
          </div>
        </div>
      </template>

      <!-- enabled -->
      <template #[`item.enabled`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            size="30"
            color="primary"
            class="v-avatar-light-bg primary--text me-3"
          >
            <v-icon
              size="18"
              color="primary"
            >
              {{ item.enabled ? icons.mdiCheckOutline : null }}
            </v-icon>
          </v-avatar>
        </div>
      </template>

      <!-- LAST_LOGIN -->
      <template #[`item.lastLogin`]="{item}">
        <div
          v-if="item.lastLogin"
          class="d-flex align-center"
        >
          <span class="text-capitalize"><small>{{ item.lastLogin | moment("from") }}</small></span>
        </div>
      </template>

      <!-- enabled -->
      <template #[`item.waitingOnAccountCreation`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            size="30"
            color="primary"
            class="v-avatar-light-bg primary--text me-3"
          >
            <v-icon
              size="18"
              color="primary"
            >
              {{ item.waitingOnAccountCreation ? icons.mdiCheckOutline : null }}
            </v-icon>
          </v-avatar>
        </div>
      </template>

      <!-- SNOWFLAKE_USERNAME -->
      <template #[`item.snowflakeUsername`]="{item}">
        <div
          v-if="item.snowflakeCredentials && item.snowflakeCredentials.username"
          class="d-flex align-center"
        >
          <span><v-icon
            color="info"
            left
          >{{ icons.mdiSnowflake }}</v-icon>{{ item.snowflakeCredentials.username }}</span>
        </div>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <v-list-item
              :to="{name:'apps-user-view',params:{id:item.id}}"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiFileDocumentOutline }}
                </v-icon>
                <span>Details</span>
              </v-list-item-title>
            </v-list-item> -->

            <v-list-item
              link
              @click="console.log(item)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
                <span>Create {{ serverConfig.name }} User</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
                <span>Disable User</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import {
  mdiAccountMultipleOutline,
  mdiAccountPlus,
  mdiCheckOutline,
  mdiCog,
  mdiDotsVertical,
  mdiSnowflake,
} from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, ref } from '@vue/composition-api'
import AddNewUser from './AddNewUser.vue'

export default {
  components: {
    AddNewUser,
  },
  setup() {
    const addNewUserSidebar = ref(false)
    const userListHeaders = [
      { text: 'NAME', value: 'name' },
      { text: 'ENABLED', value: 'enabled' },
      { text: 'ROLE', value: 'role' },
      { text: 'LAST LOGIN', value: 'lastLogin' },
      { text: 'WAITING TO JOIN', value: 'waitingOnAccountCreation' },
      { text: 'SNOWFLAKE USERNAME', value: 'snowflakeUsername' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },

      // { text: 'RECENT CONSUMPTION', value: 'recentConsumption' },

      // { text: 'HAS CREDENTIALS', value: 'hasPassword', sortable: false },
      // {
    ]

    const roles = computed(() => store.state.users.appRoles)
    const roleOptions = computed(() => roles.value.map(m => ({ text: m.name, value: m.id })))

    const appUsers = computed(() => {
      const getUsers = store.state.users.appUsers

      const users = getUsers.map(m => ({
        ...m,
        role: roles.value.length > 0 ? roles.value.filter(f => f.id === m.role)[0].name : null,
      }))

      return users
    })

    return {
      serverConfig,
      addNewUserSidebar,
      userListHeaders,
      roles,
      roleOptions,
      appUsers,
      avatarText,
      icons: {
        mdiCog,
        mdiAccountMultipleOutline,
        mdiCheckOutline,
        mdiDotsVertical,
        mdiSnowflake,
        mdiAccountPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>

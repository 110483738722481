import { buildRoleHierarchy } from '@/snowflake/snowflakeAdministration'

export default async function customCode() {
  const roles = buildRoleHierarchy()
  console.log('roles', roles)
}

// SAVED QUERIES BELOW
// ///////////////////

// Calling a Cloud Function - payload: { functionName, payload: { account } }
// const sendToFunction = httpsCallable(functions, 'callFunction')
// const payload = { account: store.state.accountProfile }
// const response = await sendToFunction({
//   functionName: 'schemaData',
//   payload,
// })
// console.log('Response', response)

// Historical Consumption Data Loads | payload: { account }
// const sendToFunction = httpsCallable(functions, 'callFunction')
// const payload = { account: store.state.accountProfile, type: 'historical' }
// const response = await sendToFunction({functionName: 'consumptionData', payload })
// console.log('RESPONSE: ', response)
// return response

<template>
  <div>
    <span>Role Management Coming Soon</span>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {} from '@mdi/js'
import {} from '@vue/composition-api'

export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
